
// Toggle/Switch button
.switch {
    display: inline-block;
    position: relative;
    content: "";
    min-height: 20px;
    margin: 0 12px 6px 0;
    padding: 1px 0;

    color: $primary-neutral;
    font-size: 14px;
    line-height: 18px;
    // text-indent: 6px;

    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    // hide the input, keep for functionality
    input {
        &[type="checkbox"],
        &[type="radio"] {
            position: absolute;
            opacity: 0;
            cursor: default;
            width: 0;
            height: 0;
        }

        &:focus {
            ~ .togglemark {
                &::before {
                    box-shadow: 0 0 0 1px $blue-50, inset 0 0 0 1px $blue-50;
                }
            }
        }

        &:checked, &.checked {
            ~ .togglemark {
                &::before {
                    color: $neutral-05;
                    text-align: left;

                    background-color: $primary-green;
                }

                &::after {
                    left: 15px
                }

                &:hover {
                    &::before {
                        background-color: $green-60;
                    }
                }
            }

            &:focus {
                ~ .togglemark {
                    &::before {
                        box-shadow: 0 0 0 1px $blue-50, inset 0 0 0 1px $blue-50, inset 0 0 0 2px $neutral-05;
                    }
                }
            }
        }
    }

    // define styles for the visual que of the switch/toggle button
    .togglemark {
        position: relative;
        cursor: pointer;
        padding-left: 40px;
        // margin-right: 0;

        &::before, &::after {
            position: absolute;
            display: block;
            content: '';
        }

        &::before {
            width: 28px;
            height: 16px;
            top: 2px;
            left: 1px;

            color: $primary-neutral;
            font: {
                size: 12px;
                weight: $bold;
            }
            text-align: right;

            border-radius: 16px;
            background-color: $neutral-50;

            transition: background-color 120ms ease-in-out;
        }

        &::after {
            width: 12px;
            height: 12px;
            top: 4px;
            left: 3px;

            border-radius: 12px;
            background-color: $neutral-05;
            box-shadow: shadow(20) rgba($blue-90, 0.2);

            transition: left 90ms ease-in-out;
        }

        &:hover {
            box-shadow: none;

            &::before {
                background-color: $neutral-40;
            }
        }
    }

    // define the styles for the large/biger version of the switch/toggle button
    &.switch-lg {
        line-height: 24px;

        .togglemark {
            padding-left: 60px;

            &::before {
                width: 44px;
                height: 24px;
                top: -3px;

                border-radius: 24px;
            }

            &::after {
                width: 18px;
                height: 18px;
                top: 0;
                left: 4px;

                border-radius: 18px;
            }
        }

        input {
            &:checked, &.checked {
                ~ .togglemark {
                    &::after {
                        left: 24px;
                    }
                }
            }
        }
    }

    // define the styles for the version with "ON"/"OFF" text
    &.switch-label {
        line-height: 24px;

        .togglemark {
            padding-left: 75px;

            &::before {
                box-sizing: border-box;
                content: 'OFF';
                width: 62px;
                height: 24px;
                top: -3px;
                padding: 0 12px;

                line-height: 26px;
                text-indent: 0;

                border-radius: 24px;
            }

            &::after {
                width: 18px;
                height: 18px;
                top: 0;
                left: 4px;

                border-radius: 18px;
            }
        }

        input {
            &:checked, &.checked {
                ~ .togglemark {
                    &::before {
                        content: 'ON';
                    }

                    &::after {
                        left: 42px;
                    }
                }
            }
        }
    }
}

// Disabled style of the switch/toggle button
.disabled .switch input, :disabled .switch input,
.switch.disabled input, .switch:disabled input,
.switch input.disabled,
.switch input:disabled {
    &[type="checkbox"],
    &[type="radio"] {
        ~ .togglemark {
            cursor: default;
            pointer-events: none;

            &::before {
                color: $neutral-70;

                background-color: $neutral-40;
            }

            &::after {
                background-color: $neutral-20;
                box-shadow: none;
            }
        }

        &:checked, &.checked {
            ~ .togglemark {
                &::before {
                    color: $green-20;
    
                    background-color: $green-40;
                }
    
                &::after {
                    background-color: $neutral-05;
                }
            }
        }
    }
}

