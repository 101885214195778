
$focusWidth: 1px;

// Make terms and descriptions in description block tag line up side-by-side.
.dl-horizontal {
    @media (min-width: $screen-sm-min) {
        dt {
            overflow: hidden;
            float: left;
            clear: left;
            width: 162px;
    
            text-align: right;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        dd {
            margin-left: 180px;
        }
    }
}

// unstyled list, removing the default list-style and left margin.
.list-unstyled,
.list-inline {
    padding-left: 0;

    list-style: none;
}

// place all list on a single line / paragraph.
.list-inline {
    margin-left: -6px;

    > li {
        display: inline-block;
        padding-right: 6px;
        padding-left: 6px;
    }
}

// Basic list group
.list-group {
    margin: 0;
    padding: 0 30px;

    color: $primary-neutral;
    list-style: none;

    border-radius: 20px;
    background-color: $neutral-05;
    box-shadow: shadow(10) rgba($blue-90, 0.1);

    // List group components
    > .list-group-header,
    > .list-group-item,
    > .list-group-title,
    > .list-group-footer {
        overflow: hidden;
        display: block;
        clear: both;
        cursor: default;
        position: relative;
        margin: 0;
        padding: 15px 0;

        font: {
            size: 14px;
        }
        line-height: 18px;

        &:not(:last-child) {
            box-shadow: 0 1px 0 0 $neutral-20;
        }

        .badge {
            position: relative;
            float: right;
            min-width: 30px;
            top: 0;
            padding: 1px 9px;
            margin-right: 1px;

            color: $neutral-80;
            text-align: center;
            font-size: 14px;
            line-height: 18px;

            border-radius: 20px;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    // header block of the list group
    > .list-group-header {
        margin-right: -30px;
        margin-left: -30px;
        padding: 15px 30px;

        color: $neutral-05;

        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        background-color: $primary-green;
        box-shadow: shadow(20) rgba($blue-90, 0.15);

        .badge {
            background-color: $neutral-05;
        }
    }

    // list item default state
    > .list-group-item {
        &:last-child {
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 20px;
        }

        // active state for a list item
        &.active {
            margin-right: -30px;
            margin-left: -30px;
            padding: 15px 30px;

            background-color: $neutral-20;

            &::before {
                display: block;
                position: absolute;
                content: '';
                width: 5px;
                height: 100%;
                top: 0;
                left: 0;

                background-color: $green-60;
            }
        }

        // disabled state for a list item
        &.disabled {
            &, &:hover, &:active, &:focus {
                cursor: default;
                pointer-events: none;
                outline: none;

                color: $neutral-50;
            }

            .badge {
                color: $neutral-50;
            }
        }

        // title element in the list item of the list group
        .list-group-item-heading,
        .list-group-item-text {
            margin-bottom: 12px;

            color: $primary-neutral;
            font: {
                size: 14px;
                weight: $normal;
            }
            line-height: 18px;
        }

        // 
        .list-group-item-text.mutted {
            color: $neutral-80;
        }
    }

    // define default state for a clickable list item
    > a.list-group-item {
        cursor: pointer;

        color: $primary-neutral;
        text-decoration: none;

        border: 0;
        background-color: transparent;

        // full width of list item for active focus and hover state
        &:not(.disabled):hover,
        &:not(.disabled):focus,
        &.active {
            margin-right: -30px;
            margin-left: -30px;
            padding: 15px 30px;
        }

        // hover state for list item
        &:not(.disabled):not(.active):hover {
            background-color: $blue-10;
            box-shadow: 0 1px 0 0 $blue-10;
        }

        // focus state for list item
        &:not(.disabled):focus {
            outline: 0;

            &, &:hover {
                box-shadow: inset 0 0 0 $focusWidth $primary-blue;
            }
        }

        // active state for list item
        &.active {
            &:focus::before {
                box-shadow: inset $focusWidth $focusWidth 0 0 $primary-blue, inset 0 -2px 0 0 $primary-blue;
            }
        }
    }

    // title element group
    > .list-group-title {
        &, &:hover, &:focus, &:active {
            pointer-events: none;

            font: {
                size: 16px;
                weight: $bold;
            }
            line-height: 24px;
        }

        .badge {
            top: 4px;

            color: $primary-neutral;
            font-size: 12px;

            box-shadow: 0 0 0 1px $neutral-30;
        }
    }

    // alternative style for the list group
    &.list-group-primary {
        background-color: $primary-blue;

        // default state for list items in the alternative list group
        > .list-group-item,
        > .list-group-title {
            color: $neutral-05;

            &:not(:last-child) {
                box-shadow: 0 1px 0 0 $blue-50;
            }

            .badge {
                color: $neutral-05;
                font-size: 12px;

                box-shadow: 0 0 0 1px $blue-30;
            }
        }

        > .list-group-item {
            // active state for the alternative list item
            &.active {
                background-color: $blue-70;
                box-shadow: none;

                &::before {
                    display: none;
                }
            }

            // disabled state for the alternative list item
            &.disabled {
                &, .badge {
                    color: $blue-20;
                }
            }

            // badge element for the alternative list group
            .badge {
                &.badge-nopill {
                    padding-right: 0;

                    font-size: 14px;
                    text-align: right;

                    box-shadow: none;
                }
            }

            // title element in the list item of the list group
            .list-group-item-heading,
            .list-group-item-text {
                color: $neutral-05;
            }
        }

        // clickable list item for alternative list group
        > a.list-group-item {
            // hoverble state
            &:not(.disabled):not(.active):hover {
                background-color: rgba($neutral-05, 0.1);
                box-shadow: 0 1px 0 0 rgba($neutral-05, 0.1);
            }

            // focus state
            &:not(.disabled):focus {
                &, &:hover {
                    box-shadow: inset 0 0 0 $focusWidth $neutral-05;
                }
            }

            // active state
            &.active {
                &:focus {
                    box-shadow: shadow(10) rgba($blue-90, 0.1), inset 0 0 0 $focusWidth $neutral-05;
                }
            }
        }

        > .list-group-title {
            .badge {
                color: $primary-neutral;

                background-color: $neutral-05;
                box-shadow: shadow(20) rgba($blue-90, 0.1);
            }
        }

        // footer block of the alternative list group
        > .list-group-footer,
        > a.list-group-footer {
            cursor: default;

            &, &:not(.disabled):hover,
            &:not(.disabled):focus,
            &:active, &.active,
            &.disabled {
                margin-right: -30px;
                margin-left: -30px;
                padding: 24px 30px;

                line-height: 30px;

                border-radius: 20px;
                background-color: $blue-70;
                box-shadow: none;
            }

            // default badge block in the footer block
            .badge {
                padding-right: 0;

                font-size: 14px;
                line-height: 30px;
                text-align: right;
                vertical-align: baseline;

                box-shadow: none;

                b, strong {
                    font-size: 24px;
                }
            }
        }
    }
}


