
// Button base styles
.btn {
    display: inline-block;
    opacity: 1;
    cursor: pointer;
        
    min-width: 160px;
    max-width: 100%;
    height: 30px;
    padding: 3px 15px;
    margin: 0 2px 6px 0;
        
    color: $primary-neutral;

    font: {
        size: 14px;
        weight: 400;
    }
    line-height: 18px;
    text: {
        align: center;
        overflow: ellipsis;
        decoration: none;
        shadow: none;
    }
    vertical-align: middle;
    white-space: nowrap;
    
    border: {
        width: 1px;
        style: solid;
        color: transparent;
    }
    border-radius: $border-radius-5;
    
    user-select: none;
    touch-action: manipulation;
    box-sizing: border-box;

    //### Button base, focus state
    &:focus { outline: none; }

    //### Button base, disabled state
    &:disabled, &.disabled,
    :disabled &, .disabled & {
        pointer-events: none;
        cursor: default;
    }

    
    //### Buttons and buttons group size
    // //=== Small buttons
    // &.btn-sm,
    // .btn-group-sm & { }

    //=== Large buttons
    &.btn-lg,
    .btn-group-lg & {
        height: 48px;
        padding: 5px 15px 7px 15px;

        line-height: 36px;
    }

    // block level buttons - those that span the full width of a parent
    &.btn-block {
        display: block;
        width: 100%;
    }
}

// fix inner text of buttons on ancher tag's
a.btn {
    padding-top: 5px;
}