

// Top navigation - Product dropdown area

.navbar-default,
.navbar-secondary {
    .navbar-header {
        .navbar-brand {
            > .dropdown-toggle { padding: 1.125rem 3.6rem 1.125rem 1.375rem; }

            .dropdown-menu {
                top: 100%;
                padding: 0;
                z-index: $zindex-navbar-dropdown;

                > li {
                    > a {
                        border-top: 1px solid $neutral-20;
                        font: $font-md;
                        padding: 0.625rem 1.875rem;

                        img { margin-right: 0.9375rem; }

                        > span {
                            position: relative;
                            top: 2px;
                        }

                        &.active {
                            background-color: $neutral-20;
                            position: relative;

                            &::before {
                                content: "";
                                display: block;
                                position: absolute;
                                width: 6px;
                                height: 100%;
                                left: 0;
                                top: 0;
                                background-color: $green-60;
                            }
                        }

                        &:focus { box-shadow: none; }
                    }
                }
            }
            
            &.open {
                .dropdown-toggle {
                    background-color: $white;
                    color: $primary-neutral;

                    .caret { background-position: -101px -3px; }
                }
            }
        }
    }
}

.navbar-default,
.navbar-secondary {
    &.navbar.is-mobile {
        .navbar-collapse{
            .first-level {
                .menudrop {
                    &.open {
                        > .dropdown-menu {
                            .navbar-brand {
                                .dropped-apps-toggle {
                                    display: block;
                                    color: $primary-blue;
                                    padding-left: 2.8125rem;
                                    position: relative;

                                    > .caret {
                                        background-position: -101px -6px;
                                        left: 1.7rem;
                                        top: 1.5625rem;
                                        transform: rotate(270deg);
                                    }
                                }
                                
                                &.is-open {
                                    .dropped-apps-toggle {
                                        padding-left: 1.875rem;

                                        > .caret {
                                            transform: rotate(90deg);
                                            position: relative;
                                            top: 1px;
                                            left: auto;
                                            right: -.55rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    }
}

