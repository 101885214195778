
// Button Primary Theme
.btn {
    &.btn-primary {
        color: $neutral-05;

        border-color: $primary-green;
        background-color: $primary-green;
        box-shadow: shadow(20) rgba($green-80, 0.20);

        //### '.btn-primary' hover state
        &:hover {
            border-color: $green-60;
            background-color: $green-60;
        }

        //### '.btn-primary' focus state
        &:focus {
            border-color: $blue-50;
            box-shadow: inset 0 0 0 1px $blue-50, inset 0 0 0 2px $neutral-05, shadow(20) rgba($green-80, 0.20);
        }

        //### '.btn-primary' active/pressed and active/selected state
        &:active, &.active {
            border-color: $green-80;
            background-color: $green-80;
            box-shadow: none;
        }

        //### '.btn-primary' disabled state
        &:disabled, &.disabled,
        :disabled &, .disabled & {
            color: $green-20;

            border-color: $green-40;
            background-color: $green-40;
            box-shadow: none;
        }
    }
}
