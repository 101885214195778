
// Button pills/facets
.nav {
    //
    &.nav-pills {
        margin: 0;
        padding: 0;
        
        list-style: none;

        li {
            display: inline;
        }

        //## Base style for a button pills
        a, button {
            display: inline-block;
            cursor: pointer;
            padding: 2px 15px 3px;
            margin-right: 10px;
            margin-bottom: 6px;
            min-width: 160px;
            text-align: center;

            color: $primary-neutral;

            font: {
                size: 14px;
                weight: 400;
            }
            line-height: 18px;
            text-decoration: none;

            outline: 0;
            border: {
                width: 1px;
                style: solid;
                color: $neutral-50;
            }
            border-radius: $border-radius-20;
            background-color: $neutral-05;
            box-shadow: shadow(20) rgba($blue-90, .12);

            //### pills hover state
            &:hover {
                border-color: $blue-20;
                background-color: $blue-10;
                box-shadow: shadow(20) rgba($blue-50, 0.15);
            }

            //### pills focus state
            &:focus {
                border-color: $blue-50;
                box-shadow: inset 0 0 0 1px $blue-50, shadow(20) rgba($blue-50, 0.15);
            }

            //### pills active/pressed state
            &:active {
                &, &.active {
                    border-color: $neutral-50;
                    background-color: $neutral-40;
                    box-shadow: none;
                }
            }

            //### pills active/selected state
            &.active {
                pointer-events: none;
                cursor: default;

                &, &:focus {
                    border-color: $neutral-50;
                    background-color: $neutral-20;
                    box-shadow: none;
                }
            }

            //### pills disabled state
            &:disabled, &.disabled {
                cursor: default;
                pointer-events: none;
    
                color: $neutral-50;
    
                border-color: $neutral-40;
                background-color: $neutral-05;
                box-shadow: none;
            }
        }


        //# Alternative styling for pill/facet buttons
        &.nav-pills-primary {
            a, button {
                &.active {
                    color: $neutral-05;

                    border-color: $primary-green;
                    background-color: $primary-green;
                }
            }
        }


        //# Disabled state for all pills in wrapper ('.nav.nav-pills')
        :disabled &, .disabled &,
        &:disabled, &.disabled {
            a, button {
                cursor: default;
                pointer-events: none;
    
                color: $neutral-50;
    
                border-color: $neutral-40;
                background-color: $neutral-05;
                box-shadow: none;
            }
        }
    }
}



