
// Top navigation base styles

body {
    padding-top: 100px; /* When top navigation/main nav has only one level */ 

    /* When top navigation/main nav has 2 levels */
    &.nav-space { 
      padding-top: 145px; 

      @media (max-width: $screen-sm-min) { padding-top: 100px; }
    }
}

.hidden {
    display: none !important;
    visibility: hidden !important;
}

.active-on-mobile { display: none; }

.navbar {
    height: auto;
    margin-bottom: 1.5rem;
    min-height: 66px;
    min-width: 320px;
    padding: 0;
    position: relative;
    z-index: $zindex-navbar;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;

    .dropdown-toggle {
        .caret {
            background-image: url("#{$image-path}/arrow-large.svg");
            width: 11px;
            height: 11px;
            position: absolute;
            top: 27px;
            right: 1.875rem;
        }

        &::before { display: none; }
    }

    .dropdown-menu { box-shadow: shadow(40) rgba($blue-90, 0.15); }

    .has-children { position: relative; }

    a:focus{
        outline: none;
        box-shadow: none;
    }
}


/* Product dropdown area */
.navbar-default,
.navbar-secondary {
    .navbar-header {
        float: left;
        position: relative;

        .navbar-brand {
            font: $font-lg;
            margin: 0;
            padding: 0;
            display: block;

            > a {
                display: block;
                text-decoration: none;
                padding: 1.125rem 1.375rem;
                //max-width: 300px;
                min-width: 300px;
                @include text-overflow;
            }

            > a:not(.dropdown-toggle) {
                > .caret { display: none; }
            }
        }
    }
}



/* navbar */
.navbar-default,
.navbar-secondary {
    .navbar-collapse {
        position: relative;
        width: 100%;

        .nav {
            margin: 0;
            padding: 0;

            li {
                list-style: none;
                margin: 0;
                padding: 0;
                position: static;

                &:hover {
                    background-color: transparent;
                }

                a {
                    display: block;
                    font: $font-md;
                    line-height: 1.9;
                    text-decoration: none;
                    padding: 1.125rem 1.875rem; 

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }
            }

            & > li { float: left; }
        }

        .first-level.navbar-right {
            float: right;
            position: relative;

            > .icon {
                &:last-child:not(.user-dropdown) { margin-right: 1.25rem; }

                &.attention {
                    > a { position: relative; }

                    a::before{
                        content: "";
                        display: block;
                        width: 9px;
                        height: 9px;
                        background-color: $orange-60;
                        border-radius: 4px;
                        position: absolute;
                        right: 5px;
                        top: 13px;
                        z-index: 1;
                    }
                }

                & > a {
                    height: 66px;
                    padding: 1.125rem 0.75rem;
                    position: relative;

                    .badge {
                        width: 9px;
                        height: 9px;
                        position: absolute;
                        background: $orange-60;
                        text-indent: -9999px;
                        overflow: hidden;
                        border-radius: $border-radius-20;
                        z-index: 1;
                        right: 5px;
                        top: 13px;
                    }

                    .vismaicon {
                        margin-right: 0;
                        top: 3px;
                    }
                }
            }

            .user-dropdown {
                margin-left: 1.25rem;

                > .dropdown-toggle {
                    width: auto;
                    height: 66px;
                    min-width: 300px;
                    padding: 0.5rem 4rem 0.75rem 1.875rem;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    &:focus { box-shadow: none; }

                    > small {
                        font: $font-sm;
                        display: block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        margin-top: -4px;
                    }

                    .vismaicon { display: none; }
                }

                &.icon {
                    .dropdown-toggle {
                        font-size: 0px;
                        text-indent: -9999px;
                        width: 105px;
                        min-width: 1%;

                        .vismaicon {
                            display: inline-block;
                            position: absolute;
                            left: 1.875rem;
                            top: 1.3rem;
                        }
                    }
                }

                .company-selection {
                    top: 100%;
                    background: $white;
                    position: absolute;
                    right: 0;
                    left: auto;
                    width: 300px;
                    padding: 1.25rem 0 0.75rem 0;
                    z-index: 1;

                    &:before {
                        content:'';
                        width: 100%;
                        height: 10px;
                        top: -5px;
                        position: absolute;
                        background-color: $white;
                    }

                    > li > a {
                        color: $primary-neutral;
                        font: $font-sm;
                        padding: .575rem 1.875rem;

                        &.log-out-link{
                            padding-left: 4rem;
                            position: relative;
                            
                            &::before {
                                content: "";
                                background: url(#{$image-path}/vismaicons/top_menu/menu_logout.svg) no-repeat -88px 0;
                                width: 24px;
                                height: 24px;
                                display: block;
                                position: absolute;
                                left: 1.875rem;
                                top: 7px;
                            }
                        }

                        &:hover { background-color: $blue-10; }
                    }

                    .divider {
                        padding: 1rem 1.875rem;
                        
                        &::after {
                            background: url("#{$image-path}/dotted-on-white.svg") repeat-x center;
                            content: "";
                            display: block;
                            height: 1px;
                        }

                        &.divider-strong {
                            &::after { background: $neutral-20; }
                        }

                        &:hover { background-color: $white; }
                    }
                }

                .company-selection-area {
                    &.one-context {
                        display: none;

                        +.divider { display: none; }
                    }
                }

                .user-details-area > div {
                    display: flex;
                    align-items: center;
                    padding: 0 1.875rem;
                    @include text-overflow;

                    .user-img {
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        flex: 0 0 50px;
                        background: url(#{$image-path}/user-avatar.svg) no-repeat top center;
                    }
                    .user-text {
                        padding-left: 0.75rem;

                        span {
                            display: block;
                            width: 92%;
                            @include text-overflow;
                        }
                    }
                    
                }

                &.open {
                    > .dropdown-toggle {
                        background-color: $white;
                        color: $primary-neutral;
                        box-shadow: 0 10px 15px rgba($blue-90 ,0.15);

                        .caret {
                            background-position: -101px -4px;
                        }
                    }
                }
            }
        }

        .first-level {
            float: left;
            max-height: 66px;
            position: static;

            > li > a {
                height: 66px; /*(IE Edge bug)*/
            }

            > .active {
                .second-level{
                    display: block;
                    margin: 0;
                    padding: 0;
            
                    > li:first-child {
                        > a { padding-left: 1.375rem; }
                    }
                }
            }

            .menudrop {
                position: relative;
                height: 66px;

                > .dropdown-toggle {
                    display: inline-block;
                    width: 90px;
                    height: 66px;

                    &::before{ display: none; }

                    .icon-align-justify {
                        border-radius: 5px;
                        width: 5px;
                        height: 5px;
                        position: absolute;
                        top: 33px;
                        left: 42px;

                        &::before, &::after {
                            content: "";
                            display: block;
                            border-radius: 5px;
                            width: 5px;
                            height: 5px;
                            position: absolute;
                            top: 0;
                            left: -12px;
                        }

                        &::after{ left: 12px; }
                    }
                }

                > .dropdown-menu {
                    overflow-y: auto;
                    width: auto;
                    //min-height: 92px;
                    min-height: 1%;
                    max-height: calc(100vh - 180px);
                    top: 65px;
                    right: 0;
                    padding: 8px 0;

                    @media (min-height: 980px) { max-height: 800px; }

                    > li {
                        float: none;
                        display: list-item;

                        a {
                            color: $primary-neutral;
                            padding: 0.365rem 1.5625rem;

                            &:hover {
                                background-color: $blue-10;
                                text-decoration: none; 
                            }
                        }

                        .second-level {
                            > li {
                                > a { padding-left: 2.8125rem; }
                            }
                        }
                    }
                }

                .second-level-children {
                    > a {
                        > .caret {
                            display: inline-block;
                            position: relative;
                            top: 0;
                            right: 0;
                            margin-left: 0.5rem;
                            background-image: url("#{$image-path}/arrow-medium.svg");
                            background-position: -4px -22px;
                            width: 11px;
                            height: 11px;
                        }
                    }

                    &.is-open {
                        > a {
                            .caret { background-position: -4px -3px; }
                        }

                        .second-level {
                            box-shadow: none;
                            display: block;
                            margin: 0;
                            padding: 0;
                            position: static;

                            li {
                                display: list-item;

                                &.open {
                                    .third-level {
                                        box-shadow: none;
                                        position: static;
                                        padding: 0;
                                        margin: 0;

                                        li {
                                            a { padding-left: 3.75rem; }
                                        }

                                        .active {
                                            a {
                                                color: $primary-blue;
                                                font-weight: $bold;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .second-level {
            display: none;
            background: $white;
            box-shadow: 0 2px 4px rgba($blue-90, 0.08);
            position: absolute;
            right: auto;
            bottom: auto;
            left: 0;
            width: 100%;
        
            & > li {
                display: inline-block;
        
                a {
                    color: $primary-neutral;
                    font: $font-sm;
                    padding: 0.95rem 0.75rem;
                    
                    &.dropdown-toggle {
                        > .caret {
                            display: inline-block;
                            position: relative;
                            top: 0;
                            right: 0;
                            margin-left: 0.5rem;
                            background-image: url("#{$image-path}/arrow-medium.svg");
                            background-position: -83px -22px;
                        }
                    }
        
                    &:hover {
                        text-decoration: underline;
                        color: $primary-blue;
                        background-color: transparent;
                    }
                }
            }

            & > .active > a {
                color: $primary-blue;
                font-weight: $bold;
        
                > .caret { background-position: -2px -22px !important; }
            }

            & > .active.open > a {
                .caret { background-position: -2px -3px !important; }
            }
        
            & > .open {
                .third-level {
                    overflow-y: auto;
                    width: auto;
                    min-width: 150px;
                    min-height: 92px;
                    max-height: calc(100vh - 180px);
                    top: 100%;
                    margin-top: 1px;
                    padding: 0.325rem 0;

                    @media (min-height: 980px) { max-height: 800px; }


                    > li { 
                        > a { 
                            padding: 0.625rem 1.5rem; 

                            &:hover {
                                font-weight: bold;
                                text-decoration: none;
                                color: $primary-blue;
                                background-color: $blue-10;
                            }
                        }

                        &.active > a {
                            font-weight: $bold;
                            color: $primary-blue;
                            background-color: $blue-10;
                        }
                    }
                }
            }
        }
    }
}

.navbar-default,
.navbar-secondary {
    &.navbar.is-mobile {
        .navbar-collapse{
            .first-level {
                .menudrop {
                    position: static;

                    > .dropdown-toggle {
                        width: auto;
                        padding-left: 4rem;
                        font: $font-lg;

                        &::before { display: none; }
                    }

                    .icon-align-justify {
                        &,
                        &::before, 
                        &::after {
                            content: "";
                            display: block;
                            position: absolute;
                            width: 24px;
                            height: 2px;
                            left: 1.5rem;
                            top: 2.0625rem;
                            border-radius: $border-radius;
                        }

                        &::before {
                            top: -0.5rem;
                            left: 0;
                        }

                        &::after {
                            bottom: -0.5rem;
                            top: auto;
                            left: 0;
                        }
                    }

                    &.open {
                        .icon-align-justify {
                            &,
                            &::before, 
                            &::after { background: $neutral-80; }
                        }

                        >.dropdown-toggle {
                            color: $primary-neutral;

                            &::before, &::after { border-color: $primary-neutral; }
                        }

                        > .dropdown-menu {
                            width: 100%;
                            padding: 0;

                            .navbar-brand {
                                > a:not(.dropdown-toggle) {
                                    .caret { display: none; }
                                }
                            }

                            > li a {
                                padding-top: .95rem;
                                padding-bottom: .8rem;
                            }

                            a { border-bottom: 1px solid $neutral-20; }

                            > .active {
                                > a {
                                    background-color: $neutral-20;
                                    position: relative;

                                    &::before {
                                        content: "";
                                        display: block;
                                        position: absolute;
                                        width: 6px;
                                        height: 100%;
                                        left: 0;
                                        top: 0;
                                        background-color: $green-60;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .first-level.navbar-right {
                position: static;

                .user-dropdown .company-selection {
                    max-width: none;
                    width: 100%;
                    top: 66px;
                }
            }
        }
    }
}

