
// Imports
@import '../../settings/variables';

// Spinners
.spinner {
    width: 48px;
    height: 48px;

    &-sm {
        width: 24px;
        height: 24px;
    }

    &-xs {
        width: 16px;
        height: 16px;
    }
}
