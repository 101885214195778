
// Split
.btn-group {
    &.btn-split {
        position: relative;
        margin: 0 6px 6px 0;

        > .btn {
            // float: left;
            margin: 0;

            text-decoration: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;

            z-index: 1;
        }

        //# Dropdown trigger style.
        .dropdown-toggle {
            min-width: 30px;
            padding: 5px 10px;

            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;

            > .caret { display: none; }

            //## drodown arrow style
            &::before {
                right: 10px;
            }

            &, &.btn-primary {
                &::before {
                    background-position: -4px -24px;

                    .btn-toolbar-primary & {
                        background-position: -44px -24px;
                    }
                }

                //## drodown arrow style for disabled state
                &.disabled, &:disabled {
                    &::before {
                        background-position: -24px -24px;

                        .btn-toolbar-primary & {
                            background-position: -64px -24px;
                        }
                    }
                }
            }
        }

        &.open {
            z-index: 2;

            .dropdown-toggle {
                background-color: $neutral-40;
                box-shadow: -1px 0 0 0 $neutral-50, inset 6px 0 4px -4px rgba($blue-90, 0.12);

                &:hover:not(:active) {
                    background-color: $blue-10;
                    box-shadow: -1px 0 0 0 $blue-20, inset 6px 0 4px -4px rgba($blue-90, 0.12);
                }

                &:focus:not(:active) {
                    box-shadow: -1px 0 0 0 $blue-50, inset 0 0 0 1px $blue-50;
                }

                &.disabled, &:disabled {
                    ~ .dropdown-menu {
                        display: none;
                    }
                }
            }
        }

        .disabled &, :disabled &,
        &.disabled, &:disabled {
            &, &.open {
                .dropdown-toggle {
                    &, &.btn-primary {
                        &::before {
                            background-position: -24px -24px;

                            .btn-toolbar-primary & {
                                background-position: -64px -24px;
                            }
                        }
                    }
                }

                .dropdown-menu {
                    display: none;
                }
            }
        }

        &.btn-group-lg {
            .dropdown-toggle {
                min-width: 44px;

                &::before { 
                    top: 19px; 
                    right: 16px;
                }
            }
        }

        // alternative style for split buttons when is not in 'btn-toolbar'
        :not(.btn-toolbar) & {
            // primary sheme color
            &.btn-split-primary {
                .btn {
                    color: $neutral-05;

                    border-color: $primary-green;
                    background-color: $primary-green;
                    box-shadow: shadow(20) rgba($green-80, 0.20);

                    &.dropdown-toggle {
                        &::before {
                            background-position: -44px -24px;
                        }

                        &.disabled, &:disabled {
                            &::before {
                                background-position: -64px -24px;
                            }
                        }
                    }

                    &:hover {
                        border-color: $green-60;
                        background-color: $green-60;
                    }
                    
                    &:focus {
                        &:not(:active) {
                            border-color: $blue-50;
                            box-shadow: inset 0 0 0 1px $blue-50, inset 0 0 0 2px $neutral-05, shadow(20) rgba($green-80, 0.20);

                            +.btn {
                                box-shadow: -1px 0 0 0 $blue-50, shadow(20) rgba($green-80, 0.20);
                                
                                &.disabled, &:disabled {
                                    box-shadow: -1px 0 0 0 $blue-50, inset 6px 0 4px -4px rgba($blue-90, 0.12);
                                }
                            }
                        }
                    }

                    &:active {
                        border-color: $green-80;
                        background-color: $green-80;
                    }

                    &.disabled, &:disabled {
                        color: $green-20;
            
                        &, &:focus {
                            border-color: $green-40;
                            background-color: $green-40;
                            box-shadow: none;
        
                            + .btn {
                                box-shadow: -1px 0 0 0 $green-70, -6px 0 4px -4px rgba($blue-90, 0.12), shadow(20) rgba($green-80, 0.20);
        
                                &.disabled, &:disabled {
                                    &, &:focus:not(:active) {
                                        box-shadow: -1px 0 0 0 $green-50;
                                    }
                                }
                            }
                        }
                    }

                    + .btn {
                        box-shadow: -1px 0 0 0 $green-80, shadow(20) rgba($green-80, 0.20);
        
                        &:focus:not(:active) {
                            box-shadow: -1px 0 0 0 $blue-50, inset 0 0 0 1px $blue-50, inset 0 0 0 2px $neutral-05, shadow(20) rgba($green-80, 0.20);
        
                            &.disabled, &:disabled {
                                box-shadow: -1px 0 0 0 $green-50, inset 6px 0 4px -4px rgba($blue-90, 0.12);
                            }
                        }
                        
                        &.disabled, &:disabled {
                            box-shadow: -1px 0 0 0 $green-50, inset 6px 0 4px -4px rgba($blue-90, 0.12);
                        }
                    }
                }

                // split buttons style for opend dropdown menu
                &.open {
                    .btn.dropdown-toggle {
                        border-color: $green-80;
                        background-color: $green-80;
                        box-shadow: -1px 0 0 0 $green-80, inset 6px 0 4px -4px rgba($blue-90, 0.12);
        
                        &:hover:not(:active) {
                            border-color: $green-60;
                            background-color: $green-60;
                            box-shadow: -1px 0 0 0 $green-70, inset 6px 0 4px -4px rgba($blue-90, 0.12);
                        }
        
                        &:focus:not(:active) {
                            border-color: $blue-50;
                            box-shadow: -1px 0 0 0 $blue-50, inset 0 0 0 1px $blue-50, inset 0 0 0 2px $neutral-05;
                        }
        
                        &.disabled, &:disabled {
                            &, &:focus {
                                border-color: $green-40;
                                background-color: $green-40;
                            }
                        }
                    }
                }
            }
        }
    }
}

// split primary color style for disabled state
.disabled:not(.btn-toolbar) .btn-group.btn-split.btn-split-primary,
.disabled:not(.btn-toolbar) .btn-group.btn-split.btn-split-primary.open,
:not(.btn-toolbar):disabled .btn-group.btn-split.btn-split-primary,
:not(.btn-toolbar):disabled .btn-group.btn-split.btn-split-primary.open,
:not(.btn-toolbar) .btn-group.btn-split.btn-split-primary.disabled,
:not(.btn-toolbar) .btn-group.btn-split.btn-split-primary.disabled.open,
:not(.btn-toolbar) .btn-group.btn-split.btn-split-primary:disabled,
:not(.btn-toolbar) .btn-group.btn-split.btn-split-primary.open:disabled {
    .btn {
        &.dropdown-toggle {
            &::before {
                background-position: -64px -24px;
            }
        }

        &, &:focus {
            color: $green-20;

            border-color: $green-40;
            background-color: $green-40;
            box-shadow: none;

            + .btn {
                &, &:focus {
                    box-shadow: -1px 0 0 0 $green-50;
                }
            }
        }
    }
}
