
/**** Individual icons - worksurface icons ****/
.vismaicon-print {
    &:before { background-image: url('#{$icons-path}/worksurface/24_print.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_print.svg'); }
}

.vismaicon-edit { 
    &:before { background-image: url('#{$icons-path}/worksurface/24_edit.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_edit.svg'); }
}

.vismaicon-save {
    &:before { background-image: url('#{$icons-path}/worksurface/24_save.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_save.svg'); }
}

.vismaicon-delete {
    &:before { background-image: url('#{$icons-path}/worksurface/24_delete.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_delete.svg'); }
}

.vismaicon-attach {
    &:before { background-image: url('#{$icons-path}/worksurface/24_attach.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_attach.svg'); }
}

.vismaicon-briefcase {
    &:before { background-image: url('#{$icons-path}/worksurface/24_briefcase.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_briefcase.svg'); }
}

.vismaicon-new { 
    &:before { background-image: url('#{$icons-path}/worksurface/24_new.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_new.svg'); }
}

.vismaicon-comment {
    &:before { background-image: url('#{$icons-path}/worksurface/24_comment.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_comment.svg'); }
}

.vismaicon-unread-comment {
    &:before { background-image: url('#{$icons-path}/worksurface/24_unread_comment.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_unread_comment.svg'); }
}

.vismaicon-add-comment {
    &:before { background-image: url('#{$icons-path}/worksurface/24_add_comment.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_add_comment.svg'); }
}

.vismaicon-zoom-in {
    &:before { background-image: url('#{$icons-path}/worksurface/24_zoom_in.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_zoom_in.svg'); }
}

.vismaicon-zoom-out {
    &:before { background-image: url('#{$icons-path}/worksurface/24_zoom_out.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_zoom_out.svg'); }
}

.vismaicon-undo {
    &:before { background-image: url('#{$icons-path}/worksurface/24_undo.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_undo.svg'); }
}

.vismaicon-redo {
    &:before { background-image: url('#{$icons-path}/worksurface/24_redo.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_redo.svg'); }
}

.vismaicon-flag {
    &:before { background-image: url('#{$icons-path}/worksurface/24_flag.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_flag.svg'); }
}

.vismaicon-cut {
    &:before { background-image: url('#{$icons-path}/worksurface/24_cut.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_cut.svg'); }
}

.vismaicon-copy {
    &:before { background-image: url('#{$icons-path}/worksurface/24_copy.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_copy.svg'); }
}

.vismaicon-paste {
    &:before { background-image: url('#{$icons-path}/worksurface/24_paste.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_paste.svg'); }
}

.vismaicon-report {
    &:before { background-image: url('#{$icons-path}/worksurface/24_report.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_report.svg'); }
}

.vismaicon-home {
    &:before { background-image: url('#{$icons-path}/worksurface/24_home.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_home.svg'); }
}

.vismaicon-refresh {
    &:before { background-image: url('#{$icons-path}/worksurface/24_refresh.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_refresh.svg'); }
}

.vismaicon-user {
    &:before { background-image: url('#{$icons-path}/worksurface/24_user.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_user.svg'); }
}

.vismaicon-users {
    &:before { background-image: url('#{$icons-path}/worksurface/24_users.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_users.svg'); }
}

.vismaicon-time {
    &:before { background-image: url('#{$icons-path}/worksurface/24_time.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_time.svg'); }
}

.vismaicon-reminder {
    &:before { background-image: url('#{$icons-path}/worksurface/24_reminder.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_reminder.svg'); }
}

.vismaicon-search {
    &:before { background-image: url('#{$icons-path}/worksurface/24_search.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_search.svg'); }
}

.vismaicon-note {
    &:before { background-image: url('#{$icons-path}/worksurface/24_note.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_note.svg'); }
}

.vismaicon-graphs {
    &:before { background-image: url('#{$icons-path}/worksurface/24_graphs.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_graphs.svg'); }
}

.vismaicon-email {
    &:before { background-image: url('#{$icons-path}/worksurface/24_email.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_email.svg'); }
}

.vismaicon-employee { 
    &:before { background-image: url('#{$icons-path}/worksurface/24_employee.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_employee.svg'); }
}

.vismaicon-phone {
    &:before { background-image: url('#{$icons-path}/worksurface/24_phone.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_phone.svg'); }
}

.vismaicon-locked {
    &:before { background-image: url('#{$icons-path}/worksurface/24_locked.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_locked.svg'); }
}

.vismaicon-unlocked {
    &:before { background-image: url('#{$icons-path}/worksurface/24_unlocked.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_unlocked.svg'); }
}

.vismaicon-money {
    &:before { background-image: url('#{$icons-path}/worksurface/24_money.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_money.svg'); }
}

.vismaicon-web {
    &:before { background-image: url('#{$icons-path}/worksurface/24_web.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_web.svg'); }
}

.vismaicon-open {
    &:before { background-image: url('#{$icons-path}/worksurface/24_open.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_open.svg'); }
}

.vismaicon-upload {
    &:before { background-image: url('#{$icons-path}/worksurface/24_upload.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_upload.svg'); }
}

.vismaicon-download {
    &:before { background-image: url('#{$icons-path}/worksurface/24_download.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_download.svg'); }
}

.vismaicon-preview {
    &:before { background-image: url('#{$icons-path}/worksurface/24_preview.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_preview.svg'); }
}

.vismaicon-favourite {
    &:before { background-image: url('#{$icons-path}/worksurface/24_favourite.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_favourite.svg'); }
}

.vismaicon-like {
    &:before { background-image: url('#{$icons-path}/worksurface/24_like.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_like.svg'); }
}

.vismaicon-dislike {
    &:before { background-image: url('#{$icons-path}/worksurface/24_dislike.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_dislike.svg'); }
}

.vismaicon-filter {
    &:before { background-image: url('#{$icons-path}/worksurface/24_filter.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_filter.svg'); }
}

.vismaicon-office-word {
    &:before { background-image: url('#{$icons-path}/worksurface/24_word.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_word.svg'); }
}

.vismaicon-office-powerpoint {
    &:before { background-image: url('#{$icons-path}/worksurface/24_powerpoint.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_powerpoint.svg'); }
}

.vismaicon-office-excel {
    &:before { background-image: url('#{$icons-path}/worksurface/24_excel.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_excel.svg'); }
}

.vismaicon-export {
    &:before { background-image: url('#{$icons-path}/worksurface/24_export.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_export.svg'); }
}

.vismaicon-pdf {
    &:before { background-image: url('#{$icons-path}/worksurface/24_pdf.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_pdf.svg'); }
}

.vismaicon-changelog {
    &:before { background-image: url('#{$icons-path}/worksurface/24_changelog.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_changelog.svg'); }
}

.vismaicon-replace {
    &:before { background-image: url('#{$icons-path}/worksurface/24_replace.svg'); }
    &.vismaicon-sm:before { background-image: none; }
}

.vismaicon-break {
    &:before { background-image: url('#{$icons-path}/worksurface/24_break.svg'); }
    &.vismaicon-sm:before { background-image: none; }
}

.vismaicon-drag {
    &:before { background-image: url('#{$icons-path}/worksurface/24_Drag.svg'); }
    &.vismaicon-sm:before { background-image: none; }
}

.vismaicon-image-document {
    &:before { background-image: url('#{$icons-path}/worksurface/24_image_document.svg'); }
    &.vismaicon-sm:before { background-image: none; }
}

.vismaicon-xml-document {
    &:before { background-image: url('#{$icons-path}/worksurface/24_xml_document.svg'); }
    &.vismaicon-sm:before { background-image: none; }
}

.vismaicon-add-user {
    &:before { background-image: url('#{$icons-path}/worksurface/24_add_user.svg'); }
    &.vismaicon-sm:before { background-image: none; }
}

.vismaicon-alert {
    &:before { background-image: url('#{$icons-path}/worksurface/24_alert.svg'); }
    &.vismaicon-sm:before { background-image: none; }
}

.vismaicon-announcement {
    &:before { background-image: url('#{$icons-path}/worksurface/24_announcement.svg'); }
    &.vismaicon-sm:before { background-image: none; }
}

.vismaicon-archive-document {
    &:before { background-image: url('#{$icons-path}/worksurface/24_archive_document.svg'); }
    &.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_archive_document.svg'); }
}

.vismaicon-breadcrumb-folder {
    &:before { background-image: url('#{$icons-path}/worksurface/24_breadcrumb_folder.svg'); }
    &.vismaicon-sm:before { background-image: none; }
}

.vismaicon-contract {
    &:before { background-image: url('#{$icons-path}/worksurface/24_contract.svg'); }
    &.vismaicon-sm:before { background-image: none; }
}

.vismaicon-database {
&:before { background-image: url('#{$icons-path}/worksurface/24_database.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-detach {
&:before { background-image: url('#{$icons-path}/worksurface/24_detach.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-document-info {
&:before { background-image: url('#{$icons-path}/worksurface/24_document_info.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-education {
&:before { background-image: url('#{$icons-path}/worksurface/24_education.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-enter-full-screen {
&:before { background-image: url('#{$icons-path}/worksurface/24_enter_full_screen.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-exit-full-screen {
&:before { background-image: url('#{$icons-path}/worksurface/24_exit_full_screen.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-evaluate {
&:before { background-image: url('#{$icons-path}/worksurface/24_evaluate.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-exclude {
&:before { background-image: url('#{$icons-path}/worksurface/24_exclude.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-folder-building {
&:before { background-image: url('#{$icons-path}/worksurface/24_folder_building.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-hourglass {
&:before { background-image: url('#{$icons-path}/worksurface/24_hourglass.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-link {
&:before { background-image: url('#{$icons-path}/worksurface/24_link.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-location {
&:before { background-image: url('#{$icons-path}/worksurface/24_location.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-logon-failed {
&:before { background-image: url('#{$icons-path}/worksurface/24_logon_failed.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-lunch {
&:before { background-image: url('#{$icons-path}/worksurface/24_lunch.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-note-blank {
&:before { background-image: url('#{$icons-path}/worksurface/24_note_blank.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-note-filled {
&:before { background-image: url('#{$icons-path}/worksurface/24_note_filled.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-office-building {
&:before { background-image: url('#{$icons-path}/worksurface/24_office_building.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-panel {
&:before { background-image: url('#{$icons-path}/worksurface/24_panel.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-price {
&:before { background-image: url('#{$icons-path}/worksurface/24_price.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-rotate-ccw {
&:before { background-image: url('#{$icons-path}/worksurface/24_rotate_ccw.svg'); }
&.vismaicon-sm:before { background-image: none; }
}
    
.vismaicon-rotate-cw {
&:before { background-image: url('#{$icons-path}/worksurface/24_rotate_cw.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-secret-document {
&:before { background-image: url('#{$icons-path}/worksurface/24_secret_document.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-send {
&:before { background-image: url('#{$icons-path}/worksurface/24_send.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-sending-failed {
&:before { background-image: url('#{$icons-path}/worksurface/24_sending_failed.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-shared {
&:before { background-image: url('#{$icons-path}/worksurface/24_shared_with_me.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-sound {
&:before { background-image: url('#{$icons-path}/worksurface/24_sound.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-text-document {
&:before { background-image: url('#{$icons-path}/worksurface/24_text_document.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-undifined-document {
&:before { background-image: url('#{$icons-path}/worksurface/24_undifined_document.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-user-folder {
&:before { background-image: url('#{$icons-path}/worksurface/24_user_folder.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-user-placeholder {
&:before { background-image: url('#{$icons-path}/worksurface/24_user_placeholder.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-video {
&:before { background-image: url('#{$icons-path}/worksurface/24_video.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-xml {
&:before { background-image: url('#{$icons-path}/worksurface/24_xml.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-filter-column {
&:before { background-image: url('#{$icons-path}/worksurface/24_filter_column.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-agreement {
&:before { background-image: url('#{$icons-path}/worksurface/24_agreement.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-change-owner {
&:before { background-image: url('#{$icons-path}/worksurface/24_change_owner.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-change-with {
&:before { background-image: url('#{$icons-path}/worksurface/24_change_with.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-coffee-break {
&:before { background-image: url('#{$icons-path}/worksurface/24_coffee_break.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-comment-chat {
&:before { background-image: url('#{$icons-path}/worksurface/24_comment_chat.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-comment-community {
&:before { background-image: url('#{$icons-path}/worksurface/24_comment_community.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-comment-web-reply {
&:before { background-image: url('#{$icons-path}/worksurface/24_comment_web_reply.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-date-picker {
&:before { background-image: url('#{$icons-path}/worksurface/24_date_picker.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-encrypted-pdf {
&:before { background-image: url('#{$icons-path}/worksurface/24_encrypted_pdf.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-graph {
&:before { background-image: url('#{$icons-path}/worksurface/24_graph.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-jira {
&:before { background-image: url('#{$icons-path}/worksurface/24_jira.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-list-view {
&:before { background-image: url('#{$icons-path}/worksurface/24_list_view.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-payroll-calculations {
&:before { background-image: url('#{$icons-path}/worksurface/24_payroll_calculations.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-platform-cloud {
&:before { background-image: url('#{$icons-path}/worksurface/24_platform_cloud.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-platform-mobile {
&:before { background-image: url('#{$icons-path}/worksurface/24_platform_mobile.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-report-blue {
&:before { background-image: url('#{$icons-path}/worksurface/24_report_blue.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-sort-list {
&:before { background-image: url('#{$icons-path}/worksurface/24_sort_list.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-storage {
&:before { background-image: url('#{$icons-path}/worksurface/24_storage.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-tile-view {
&:before { background-image: url('#{$icons-path}/worksurface/24_tile_view.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-user-list-1 {
&:before { background-image: url('#{$icons-path}/worksurface/24_user_list_1.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-user-list-2 {
&:before { background-image: url('#{$icons-path}/worksurface/24_user_list_2.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-help {
&:before { background-image: url('#{$icons-path}/worksurface/24_help.svg'); }
&.vismaicon-sm:before { background-image: none; }
}

.vismaicon-alarm {
&:before { background-image: url('#{$icons-path}/worksurface/24_alarm.svg'); }
&.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_pdf.svg'); }
}

.vismaicon-information {
&:before { background-image: none; }
&.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_information.svg'); }
}

.vismaicon-bell {
&:before { background-image: none; }
&.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_signalisation_bell.svg'); }
}

.vismaicon-remove-bell {
&:before { background-image: none; }
&.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_remove_signalisation_bell.svg'); }
}

.vismaicon-summarize {
&:before { background-image: none; }
&.vismaicon-sm:before { background-image: url('#{$icons-path}/worksurface/16_summarize.svg'); }
}

