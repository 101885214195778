
// Imports
@import '../settings/variables';


.page-holder { 
    padding-left: 300px;  
    transition: padding-left 0.2s ease-in-out;

    @media (max-width: $screen-md-min) {
        padding-left: 50px;  
    }

    &.hidden-vert-menu {
        padding-left: 90px;
        transition: padding-left 0.2s ease-in-out;

        @media (max-width: $screen-md-min) {
            padding-left: 50px;  
        }
    }
}

.vertical-nav {
    background-color: $white;
    top: 66px; // When top navigation has only one level
    height: calc(100vh - 66px); // When top navigation has only one level
    left: 0;
    position: fixed;
    padding-bottom: 122px;
    z-index: 1;
    overflow: hidden;
    width: 300px;
    box-shadow: shadow(30) rgba($blue-90, 0.08);
	transition: width 0.2s ease-in-out;

    &:after {
        content: '';
        display: none;
        position: absolute;
        width: 90px;
        height: 100%;
        top: 0;
        right: -90px;
        box-shadow: 0 0 20px rgba(#001F33, 0.1);
    }

    &.hidden-menu {
        width: 90px;

        @media (max-width: $screen-md-min) {
            width: 50px;  
        }

        &:after { display: block; }

        ul li.show-nav > a:after { transform: rotateY(180deg); }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
 
        li {
            list-style: none;
            margin: 0;
            padding: 0;

            a {
                color: $primary-neutral;
                text-decoration: none;
                display: block;
                position: relative;

                &:hover { background-color: $blue-10; }

                &:after {
                    display: block;
                    content: '';
                    position: absolute;
                    top: 19px;
                    right: 15px;
                    height: 8px;
                    width: 13px;
                    background: transparent url(#{$image-path}/arrow-large.svg) -4px -32px no-repeat;
                }

                &:only-child:after { display: none; }
            }

            &.active {
                > a:only-child {
                    background-color: #dadfe5;
                    box-shadow: 5px 0 0 $green-60 inset;
                }
            }

            &.active,
            &.active.active-child {
                > a { 
                    background-color: $neutral-20; 
                    
                    &:hover { background-color: $blue-10; }

                    &:after { transform: rotate(180deg); }
                }
            }

            &.disabled {
                > a { 
                    color: $neutral-60; 
                    pointer-events: none;

                    &:hover { cursor: not-allowed; }

                    &:focus { outline: none; }
                }
            }

            &.show-nav {
                position: absolute;
                right: 0;
                bottom: 50px;

                > a {
                    width: 30px;
                    height: 65px;
                    display: block;
                    text-indent: -9999px;
                    padding: 0 !important;
                    background: url(#{$image-path}/vertical-nav-collapse.svg) 0px 0px transparent no-repeat;

                    &:after {
                        display: block;
                        content: '';
                        position: absolute;
                        top: 25px;
                        right: 7px;
                        height: 14px;
                        width: 14px;
                        background: transparent url(#{$image-path}/vertical-nav-collapse.svg) -8px -209px no-repeat;
                        transition: width 0.2s ease-in-out;
                    }

                    &:hover {
                        background-position: 0 -70px;
                    }
                }
            }
        }
    } 

    ul {
        overflow-y: auto;
        overflow-x: hidden;
        box-sizing: content-box;
        width: 300px;
        height: calc(100vh - 66px); // When top navigation has only one level
        scrollbar-width: none;
        padding-bottom: 120px;

        ul {
            padding: 0;
            height: auto;
        }

        > li {
            > a { padding: 12px 30px 12px 22px; }

            &.active, &.active-child {
                > ul { display: block; }
            }

            > ul {
                background: $neutral-20;
                padding-bottom: 6px;
                display: none;
                
                > li {
                    > a { padding: 9px 30px; }
                }
            }
        }
    }
   

    // Blue navigation
    &.vertical-nav-blue {
        background-color: $blue-80;
        box-shadow: 0 0 10px rgba(#001F33, 0.1);
        
        &:after { box-shadow: 0 0 20px rgba(#001F33, 0.3); }

        ul li {
            a { 
                color: $white; 

                &:hover { background-color: rgba($white, 0.1); }

                &:after { background-position: -52px -32px; }
            }

            &.active {
                > a:only-child { background-color: $blue-90; }
            }

            &.active,
            &.active.active-child {
                > a { 
                    background-color: rgba($blue-90, 0.5); 

                    &:hover { background-color: #24618a; }
                }
            }

            &.disabled {
                > a { color: rgba($neutral-60, 0.5); }
            }
            
            &.show-nav {
                > a {
                    background-position: 0px -140px;
                    background-color: transparent;

                    &:after { background-position: -8px -225px; }
                }
            }
        }
     
        > ul {
            > li {
                > ul { background: rgba($blue-90, 0.5); }
            }
        }
      
    }
}


/* When top navigation/main nav has 2 levels */
.nav-space {
    .vertical-nav {
        @media (min-width: $screen-sm-min) {
            top: 114px; // When top navigation has two levels
            height: calc(100vh - 114px); // When top navigation has two levels
        }

        > ul {
            @media (min-width: $screen-sm-min) {
                height: calc(100vh - 114px); // When top navigation has 2 levels level
            }
        }
    }
}
