
// Imports
@import '../settings/variables';

//===
img, svg {
	vertical-align: middle;
}

img:not([alt]) {
	border: 1px solid $primary-orange;
}

.img-fluid, 
.img-responsive {
	@include img-fluid();
}

.img-rounded {
	border-radius: 50%;
}

.img-thumbnail {
	padding: $thumbnail-padding;
	background-color: $thumbnail-bg;
	border: $thumbnail-border-width solid $thumbnail-border-color;
	@include border-radius($thumbnail-border-radius);
  
	// Keep them at most 100% wide
	@include img-fluid();
  }