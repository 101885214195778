
// Imports
@import '../settings/variables';

// Wizard main wrapper
.wizard {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    width: 100%;
    margin: 0;
    padding: 0;

    // wizard item general style
    .wizard-item {
        display: block;
        position: relative;
        cursor: pointer;
        flex: 1;

        height: 48px;
        padding: 3px 30px;

        color: $primary-neutral;

        font: {
            size: 14px;
            weight: 400;
        }
        line-height: 42px;
        text: {
            align: left;
            overflow: ellipsis;
            decoration: none;
            shadow: none;
        }
        vertical-align: middle;
        white-space: nowrap;

        border: {
            width: 1px 0;
            style: solid;
            color: $neutral-50 transparent;
        }
        border-radius: 0;
        background-color: $neutral-05;
        box-shadow: shadow(20) rgba($blue-90, 0.12);

        outline: 0;
        user-select: none;
        touch-action: manipulation;
        box-sizing: border-box;

        // wizzard arrow general style
        &::before,
        &::after {
            display: block;
            position: absolute;
            content: '';

            border: {
                width: 1px 1px 0 0;
                style: solid;
                color: transparent;
            }
            transform: rotate(45deg) skew(18deg, 18deg);
        } // END '.wizard .wizard-item::before'

        // left arrow part
        &::before {
            width: 26px;
            height: 26px;
            top: 10px;
            left: -13px;

            border-color: $neutral-40;
            background-color: transparent;
        } // END '.wizard .wizard-item::before'

        // right arrow part
        &::after {
            width: 24px;
            height: 24px;
            top: 11px;
            right: -12px;

            background-color: $neutral-05;
        } // END '.wizard .wizard-item::after'

        // first wizard item
        &:first-child() {
            border-left: 1px solid $neutral-50;

            &::before { display: none; }

            &:focus:not(:active) { box-shadow: inset 1px 1px 0 0 $blue-50, inset 0 -1px 0 0 $blue-50, shadow(20) rgba($blue-50, 0.15); }
        } // END '.wizard .wizard-item:first-child()'

        // wizard items z-index order
        &:nth-last-child(2)  { z-index:  2; }
        &:nth-last-child(3)  { z-index:  3; }
        &:nth-last-child(4)  { z-index:  4; }
        &:nth-last-child(5)  { z-index:  5; }
        &:nth-last-child(6)  { z-index:  6; }
        &:nth-last-child(7)  { z-index:  7; }
        &:nth-last-child(8)  { z-index:  8; }
        &:nth-last-child(9)  { z-index:  9; }
        &:nth-last-child(10) { z-index: 10; }
        &:nth-last-child(11) { z-index: 11; }
        &:nth-last-child(12) { z-index: 12; }
        &:nth-last-child(13) { z-index: 13; }
        &:nth-last-child(14) { z-index: 14; }
        &:nth-last-child(15) { z-index: 15; }
        &:nth-last-child(16) { z-index: 16; }
        &:nth-last-child(17) { z-index: 17; }
        &:nth-last-child(18) { z-index: 18; }
        &:nth-last-child(19) { z-index: 19; }

        // last wizard item specific style
        &:last-child() {
            border-right: 1px solid $neutral-50;
            z-index: 1;

            &::after { display: none; }

            &:focus:not(:active) { box-shadow: inset 0 1px 0 0 $blue-50, inset -1px -1px 0 0 $blue-50, shadow(20) rgba($blue-50, 0.15); }

        } // END '.wizard .wizard-item:last-child()'

        // wizard item on hover state
        &:hover {
            border-color: $blue-20;
            background-color: $blue-10;
            box-shadow: shadow(20) rgba($blue-50, 0.15);

            &::before { border-color: $blue-20; }

            &::after { background-color: $blue-10; }

            + .wizard-item {
                &, &:disabled, &.disabled {
                    &::before { border-color: $blue-20; }
                }
            } // END '.wizard .wizard-item:hover + .wizard-item'
        } // END '.wizard .wizard-item:hover'

        // wizard item on focus state
        &:focus:not(:active) {
            outline: none;

            border-color: $blue-50;
            box-shadow: inset 0 1px 0 0 $blue-50, inset 0 -1px 0 0 $blue-50, shadow(20) rgba($blue-50, 0.15);

            &::before {
                border-color: $blue-50;
                box-shadow: 1px -1px 0 0 $blue-50;
            } // END '.wizard .wizard-item:focus:not(:active)::before'

            + .wizard-item {
                &::before {
                    border-color: $blue-50;
                    box-shadow: 1px -1px 0 0 $blue-50;
                }
            } // END '.wizard .wizard-item:focus:not(:active) + .wizard-item'
        } // END '.wizard .wizard-item:focus:not(:active)'

        // wizard item on pressed state
        &:active {
            border-color: $neutral-50;
            background-color: $neutral-40;
            box-shadow: none;

            &::before {
                border-color: $neutral-50;
                box-shadow: 6px 0 4px -4px rgba($blue-90, 0.15);
            }

            &::after {
                background-color: $neutral-40;
                border-top-color: rgba($blue-90, 0.1);
                box-shadow: inset -2px 4px 6px -7px rgba($blue-90, 0.05);
            }

            + .wizard-item {
                &, &:disabled, &.disabled {
                    &::before {
                        border-color: $neutral-50;
                        background-color: $neutral-40;
                        box-shadow: none;
                    }
                }

                &.active {
                    &::before {
                        border-color: $green-60;
                    }
                }
            } // END '.wizard .wizard-item:active + .wizard-item'
        } // END '.wizard .wizard-item:active'

        // wizard item on disabled state
        &:disabled, &.disabled {
            pointer-events: none;
            cursor: default;

            color: $neutral-60;
            border-color: $neutral-40;
            box-shadow: none;
        
            &::before {
                border-color: $neutral-40;
                box-shadow: none;
            }

            &::after {
                border: 0;
                box-shadow: none;
            }

            &:first-child(),
            &:last-child() { box-shadow: none; }

            &:focus {
                border-color: $neutral-40;
                box-shadow: none;
            }

            + .wizard-item {
                &:not(:disabled):not(.disabled) {
                    &:hover {
                        &::before { border-color: $blue-20; }
                    }

                    &:focus:not(:active) {
                        &::before { border-color: $blue-50; }
                    }

                    &, &:active {
                        &::before { border-color: $neutral-50; }
                    }
                } // END '.wizard .wizard-item:disabled + .wizard-item:not(:disabled):not(.disabled)'

                &:disabled, &.disabled {
                    &, &:focus {
                        &::before {
                            border-color: $neutral-40;
                            box-shadow: none;
                        }
                    }
                } // END '.wizard .wizard-item:disabled + .wizard-item:disabled'
            } // END '.wizard .wizard-item:disabled + .wizard-item' or '.wizard .wizard-item.disabled + .wizard-item'
        } // END '.wizard .wizard-item:disabled' or '.wizard .wizard-item.disabled'

        // wizard item selected and visited general style
        &.visited, &.visited:active, &.visited:focus,
        &.active, &.active:active {
            pointer-events: none;
            cursor: default;

            border-color: $green-60;
            background-color: $neutral-20;
            box-shadow: none;

            &::before {
                border-color: $green-60;
                box-shadow: none;
            } // END '.wizard .wizard-item.active + .wizard-item::before' or '.wizard .wizard-item.visited + .wizard-item::before'

            &:first-child(),
            &:last-child() { box-shadow: none; }

            + .wizard-item {
                &::before {
                    border-color: $green-60;
                    box-shadow: none;
                }
            } // END '.wizard .wizard-item.active + .wizard-item' or '.wizard .wizard-item.visited + .wizard-item'
        } // END '.wizard .wizard-item.active' or '.wizard .wizard-item.visited'

        // wizard item active/selected
        &.active {
            &::after { background-color: $neutral-20; }

            &:not(:focus) {
                + .wizard-item {
                    &:hover:not(:active):not(:focus) {
                        &::before { border-color: $blue-20; }
                    }

                    &:focus:not(:active) {
                        &::before {
                            border-color: $blue-50;
                            box-shadow: 1px -1px 0 0 $blue-50;
                        }
                    } // END '.wizard .wizard-item.active:not(:focus) + .wizard-item:focus'

                    &:disabled, &.disabled {
                        &, &:focus {
                            &::before {
                                border-color: $green-60;
                                box-shadow: 6px 0 4px -4px rgba($blue-90, 0.15);
                            }
                        }
                    } // END '.wizard .wizard-item.active:not(:focus) + .wizard-item:disabled'
                } // END '.wizard .wizard-item.active:not(:focus) + .wizard-item'
            } // END '.wizard .wizard-item.active:not(:focus)'

            + .wizard-item {
                &:active {
                    &::before {
                        border-color: $green-60;
                        box-shadow: 6px 0 4px -4px rgba($blue-90, 0.15);
                    }
                } // END '.wizard .wizard-item.active + .wizard-item:active'
            } // END '.wizard .wizard-item.active + .wizard-item'
        } // END '.wizard .wizard-item.active'

        &.visited {
            &, &:focus {
                &, &::after { background-color: $neutral-05; }
            }

            // checked icon for complete/visited state
            &, &:hover, &:focus, &:active {
                background: {
                    image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="15.327" height="12.68" viewBox="0 0 15.327 12.68"%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%2368a611;%7D%3C/style%3E%3C/defs%3E%3Cpath class="a" d="M1390.032,6809.558l-1.224,1.241s2.446,2.771,4.64,5.57c5.736-6.936,10.687-11.882,10.687-11.882l-.736-.8a56.256,56.256,0,0,0-9.845,9.265C1392,6811.266,1390.032,6809.558,1390.032,6809.558Z" transform="translate(-1388.808 -6803.689)"/%3E%3C/svg%3E');
                    position: right 9px center;
                    repeat: no-repeat;
                }
            }

            + .wizard-item {
                &:focus:not(.visited):not(:active):not(:disabled):not(:disabled) {
                    &::before {
                        border-color: $blue-50;
                        box-shadow: 1px -1px 0 0 $blue-50;
                    }
                } // END '.wizard .wizard-item.visited + .wizard-item:focus'
            } // END '.wizard .wizard-item.visited + .wizard-item'
        } // END '.wizard .wizard-item.visited'

        // style for next wizard item inline
        + .wizard-item{
            &:disabled, &.disabled {
                &, &:focus {
                    &::before {
                        border-color: $neutral-50;
                        box-shadow: 6px 0 4px -4px rgba($blue-90, 0.15);
                    }
                }
            } // END '.wizard .wizard-item + .wizard-item:disabled'
        } // END '.wizard .wizard-item + .wizard-item'
    } // END '.wizard .wizard-item'
} // END '.wizard'

//===
